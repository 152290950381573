import {EntityType} from './entityType';

const MyProfile = {
  firstname: '',
  lastname: '',
  email: '',
  username: ''
}

const User = {
  firstname: '',
  lastname: '',
  username: '',
  password: '',
  email: '',
};


export const Schema = {
  [EntityType.User]: User,
  [EntityType.MyProfile]: MyProfile,
};
