import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";
import Board, { moveCard, addCard, addColumn } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
// Use your own styles to override the default styles
// import "./styles.css";


const board = {
  columns: [{
    id: 1,
          title: "Create menu",
          cards: [
            {
              id: 1,
              title: "Card title 1",
              description: "Card content"
            },
            {
              id: 2,
              title: "Card title 2",
              description: "Card content"
            },
            {
              id: 3,
              title: "Card title 3",
              expanded: true,
              description: "Card content"
            },
            {
              id: 20,
              title: "POS 2022 Kit",
              description: (
                <div>
                  <img
                    alt="Image"
                    src="https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/11/pos-d-600x400.jpg"
                    style={{ height: "80px" }}
                  />
                  <p style={{ alignContent: "center" }}>
                    Lets You Sell Stuff To Customers
                  </p>
                  <button className="btn btn-alert">alert</button>
                </div>
              )
            }
          ]
  }]
}


const apple = {
  columns: [
    {
      id: 2,
      title: "Create menu",
      cards: [
        {
          id: 10,
          title: "111111111111 1",
          description: "Card content",
        },
        {
          id: 20,
          title: "222222222222",
          description: "Card content",
        },
        {
          id: 30,
          title: "33333333333333",
          expanded: true,
          description: "Card content",
        },
      ],
    },
  ],
};


const ControlledBoard = ({board,...props}) => {
  // You need to control the state yourself.
  const [controlledBoard, setBoard] = useState(board);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    //console.log(updatedBoard)
    setBoard(updatedBoard);
  }

  function onCardNew (newCard) {
    //console.log(newCard)

    const newKard = { ...newCard,id: 21 }
    return newKard
  }

  const ColumnAdder = ({ addColumn }) => {
    return (
      <div onClick={()=> addColumn({id: `${werw}`, title: 'Title', cards:[]})}>
        Add column
      </div>
    )
  }

  const CustomCard = (card, cardbag) =>
  {
    console.log(card,cardbag)
    return (
      <>
        <p>asdasdasdasdasd</p>  
      </>
    )
  }


  return (
    <Board
      {...props}
      initialBoard={board}  
      //renderCard={CustomCard}
      //disableColumnDrag
      allowAddColumn={{ on: "right" }}
      allowRenameColumn
      allowAddCard
      allowRemoveCard
      allowRemoveColumn
      onCardRemove={console.log}
      onColumnRemove={console.log}
      onColumnRename={console.log}      
      onNewCardConfirm={onCardNew}
      onNewColumnConfirm={(draftColumn) => ({
        id: new Date().getTime(),
        title: "new Card",
        ...draftColumn
      })}      
      onCardNew={console.log} 
      ColumnAdder={ColumnAdder}
      onColumnNew={console.log}          
      onCardDragEnd={handleCardMove}
    >
      {controlledBoard}
    </Board>
  );
}

function UncontrolledBoard() {
  return (
    <>
      <Row>
          <Card>
          <CardBody>
           <Row>
              <Col lg="4">
                
                <ControlledBoard
                  board={apple}                
                />
            </Col>
  
  
            <Col lg="8">
                  <div className="flex-grow-1 align-self-center">
                  <ControlledBoard
                    board={board}                
                  />
                </div>
              </Col> 
           </Row>  
            </CardBody>
          </Card>

      </Row>      
    </>
  );
}

export default UncontrolledBoard;
