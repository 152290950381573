import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {getAxiosDefaultConfig, getId} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions";

//redux
import {connect} from "react-redux";

import { Link } from "react-router-dom";

// import images
import logoImg from "../../assets/images/logo.svg";

const Register = props => {

   //meta title
   document.title="Register | Admin Events";
  const [passwordShown, setPasswordShown] = useState(false);
  const [hasResponse, setHasResponse] = useState({});
  
  const submitForm = async (values) => {

    const url = API_URL + "/register";
    try {
      await axios
        .post(url, values, getAxiosDefaultConfig())
        .then((res) => {
          setHasResponse(res.data);
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
      username: Yup.string().required("Username is required"),
      password:
           Yup.string()
              .nullable()
              .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special character")
              .required("Password is required"),
      passwordconfirm:
           Yup.string()
              .nullable()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Password confirmation is required")
    }),
    onSubmit: (values) => {
      submitForm(values)
    },
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-dark bg-soft">
                  <Row>
                    <Col className="col-6">
                      <div className="text-dark p-4">
                        <h5 className="text-dark">Free Register</h5>
                        <p>Get your account now.</p>
                      </div>
                    </Col>
                    <Col className="col-6 align-self-end">
                      
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {/* <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="p-2">
                    {hasResponse.status && hasResponse.status !== 200 ? (
                      <Alert color="danger" style={{marginTop: "13px"}}>
                        {hasResponse.message}
                      </Alert>
                    ) : null}
                    {hasResponse && hasResponse.status === 200 ? (
                      <Alert color="success" style={{marginTop: "13px"}}>
                        {hasResponse.message}
                      </Alert>
                    ) : null}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      <div className="form-floating mb-1">
                        <input
                          className={validation.touched.email && validation.errors.email ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="email"
                          id="email"
                          value={validation.values.email || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="email"
                        />
                        <label htmlFor="email">{validation.touched.email && validation.errors.email ? <span style={{color: "red"}}>{validation.errors.email}</span> : "Enter your email"}</label>
                      </div>
                      <div className="form-floating mb-1">
                        <input
                          className={validation.touched.username && validation.errors.username ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="username"
                          id="username"
                          value={validation.values.username || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="text"
                        />
                        <label htmlFor="username">{validation.touched.username && validation.errors.username ? <span style={{color: "red"}}>{validation.errors.username}</span> : "Enter your username"}</label>
                      </div>

                      <div className="form-floating mb-1 position-relative">
                        <input
                          className={validation.errors.password ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="password"
                          id="password"
                          value={validation.values.password || ""}
                          onChange={validation.handleChange}
                          type={passwordShown ? "text" : "password"}
                        />
                        <span className="viewpass" style={{right: validation.errors.password ? "33px" : "11px"}} onClick={togglePassword}>
                          {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                        </span>
                        <label htmlFor="password">Enter your password</label>
                        {validation.errors.password ? <div className="invalid-tooltip">{validation.errors.password}</div> : null}
                      </div>

                      <div className="form-floating mb-1 position-relative">
                        <input
                          className={validation.errors.passwordconfirm ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="passwordconfirm"
                          id="passwordconfirm"
                          value={validation.values.passwordconfirm || ""}
                          onChange={validation.handleChange}
                          type={passwordShown ? "text" : "password"}
                        />
                        <span className="viewpass" style={{right: validation.errors.passwordconfirm ? "33px" : "11px"}} onClick={togglePassword}>
                          {passwordShown ? <i className="text-muted far fa-eye" /> : <i className="text-muted far fa-eye-slash" />}
                        </span>
                        <label htmlFor="passwordconfirm">Confirm password</label>
                        {validation.errors.passwordconfirm ? <div className="invalid-tooltip">{validation.errors.passwordconfirm}</div> : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block " type="submit">
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  <Link to="https://www.deous.gr" target={"_blank"}>
                    {" "}
                    Deous
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, { registerUser,apiError,registerUserFailed })(Register);

