import {API_URL} from "../../config";
import {EntityType} from './entityType'

export const ApiEndpoint = {
    [EntityType.Utility]: API_URL + "/utils",
    [EntityType.User]: API_URL + "/users",
    [EntityType.Permissions] : API_URL + "/iam/permissions",
    [EntityType.MyPermissions] : API_URL + "/my/permissions",
    [EntityType.MyProfile] : API_URL + "/my/profile",
}
