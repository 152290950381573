import React from "react";
import { Redirect } from "react-router-dom";

// File Manager
import FileManager from "../pages/FileManager/index";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// //Tasks
import TasksList from "../pages/Tasks/tasks-list";
import TasksKanban from "../pages/Tasks/tasks-kanban";
import TasksCreate from "../pages/Tasks/tasks-create";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ResetPassword";
import ResetPwd from "../pages/Authentication/RstPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Users from "../pages/Users/index";
import ProfileTab from "../pages/Users/userProfileTab";
import Accommodations from "../pages/Accommodations/index";
import Accommodation from "../pages/Accommodations/accommodation";
import MenusLists from "../pages/Menus/index";

//import Chat from "../pages/Chat/Chat";
import Chat from "../pages/Chat/newIndex";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import ReCharts from "../pages/Charts/ReCharts";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";
import DragDropTables from '../pages/Tables/DragDropTables';

//Blog
import BlogList from "../pages/Blog/BlogList/index";
import BlogGrid from "../pages/Blog/BlogGrid/index";
import BlogDetails from "../pages/Blog/BlogDetails";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import DualListbox from "../pages/Tables/DualListbox";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiOffCanvas from "pages/Ui/UiOffCanvas";
import UiBreadcrumb from '../pages/Ui/UiBreadcrumb';
import UiPlaceholders from "../pages/Ui/UiPlaceholders";
import UiToasts from "../pages/Ui/UiToast";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";


const authProtectedRoutes = [
  {path: "/dashboard", component: Dashboard},
  {path: "/users", component: Users},
  {path: "/user/:id", component: ProfileTab},
  {path: "/properties", component: Accommodations},
  {path: "/property/:id", component: Accommodation},
  { path: "/menus", component: MenusLists },
  { path: "/chat", component: Chat },

  //File Manager
  {path: "/apps-filemanager", component: FileManager},

  // //calendar
  {path: "/calendar", component: Calendar},

  // //profile
  {path: "/profile", component: UserProfile},

  // Tasks
  {path: "/tasks-list", component: TasksList},
  {path: "/tasks-kanban", component: TasksKanban},
  {path: "/tasks-create", component: TasksCreate},

  //Blog
  {path: "/blog-list", component: BlogList},
  {path: "/blog-grid", component: BlogGrid},
  {path: "/blog-details", component: BlogDetails},

  //Charts
  {path: "/apex-charts", component: ChartApex},
  {path: "/chartist-charts", component: ChartistChart},
  {path: "/chartjs-charts", component: ChartjsChart},
  {path: "/e-charts", component: EChart},
  {path: "/sparkline-charts", component: SparklineChart},
  {path: "/charts-knob", component: ChartsKnob},
  {path: "/re-charts", component: ReCharts},

  // Icons
  {path: "/icons-boxicons", component: IconBoxicons},
  {path: "/icons-dripicons", component: IconDripicons},
  {path: "/icons-materialdesign", component: IconMaterialdesign},
  {path: "/icons-fontawesome", component: IconFontawesome},

  // Tables
  {path: "/tables-basic", component: BasicTables},
  {path: "/tables-datatable", component: DatatableTables},
  {path: "/tables-responsive", component: ResponsiveTables},
  {path: "/tables-editable", component: EditableTables},
  {path: "/tables-dragndrop", component: DragDropTables},

  // Maps
  {path: "/maps-google", component: MapsGoogle},
  {path: "/maps-vector", component: MapsVector},
  {path: "/maps-leaflet", component: MapsLeaflet},

  // Forms
  {path: "/form-elements", component: FormElements},
  {path: "/form-layouts", component: FormLayouts},
  {path: "/form-advanced", component: FormAdvanced},
  {path: "/form-editors", component: FormEditors},
  {path: "/form-mask", component: FormMask},
  {path: "/form-repeater", component: FormRepeater},
  {path: "/form-uploads", component: FormUpload},
  {path: "/form-wizard", component: FormWizard},
  {path: "/form-validation", component: FormValidations},
  {path: "/form-xeditable", component: FormXeditable},
  {path: "/dual-listbox", component: DualListbox},

  // Ui
  {path: "/ui-alerts", component: UiAlert},
  {path: "/ui-buttons", component: UiButtons},
  {path: "/ui-cards", component: UiCards},
  {path: "/ui-carousel", component: UiCarousel},
  {path: "/ui-colors", component: UiColors},
  {path: "/ui-dropdowns", component: UiDropdown},
  {path: "/ui-general", component: UiGeneral},
  {path: "/ui-grid", component: UiGrid},
  {path: "/ui-images", component: UiImages},
  {path: "/ui-lightbox", component: UiLightbox},
  {path: "/ui-modals", component: UiModal},
  {path: "/ui-progressbars", component: UiProgressbar},
  {path: "/ui-tabs-accordions", component: UiTabsAccordions},
  {path: "/ui-typography", component: UiTypography},
  {path: "/ui-video", component: UiVideo},
  {path: "/ui-session-timeout", component: UiSessionTimeout},
  {path: "/ui-rating", component: UiRating},
  {path: "/ui-rangeslider", component: UiRangeSlider},
  {path: "/ui-notifications", component: UiNotifications},
  {path: "/ui-offcanvas", component: UiOffCanvas},
  {path: "/ui-breadcrumb", component: UiBreadcrumb},
  {path: "/ui-placeholders", component: UiPlaceholders},
  {path: "/ui-toasts", component: UiToasts},

  //Utility
  {path: "/pages-starter", component: PagesStarter},
  {path: "/pages-timeline", component: PagesTimeline},
  {path: "/pages-faqs", component: PagesFaqs},
  {path: "/pages-pricing", component: PagesPricing},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {path: "/", exact: true, component: () => <Redirect to="/dashboard" />},
];

const publicRoutes = [
  {path: "/logout", component: Logout},
  {path: "/login", component: Login},
  {path: "/reset-password", component: ForgetPwd},
  {path: "/reset-password/:uuid", component: ResetPwd},
  {path: "/register", component: Register},

  {path: "/pages-maintenance", component: PagesMaintenance},
  {path: "/pages-comingsoon", component: PagesComingsoon},
  {path: "/pages-404", component: Pages404},
  {path: "/pages-500", component: Pages500},
];

export { authProtectedRoutes, publicRoutes };
