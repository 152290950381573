import {EntityType} from "../core/entityType";
import {Operation} from "../core/operation";

export const entityType = EntityType.User;
export const entityOperations = [
  Operation.getAll,
  Operation.get,
  Operation.create,
  Operation.delete,
  Operation.update,
];
