import {all, fork, call, put, takeEvery, } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER} from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import {postLogin} from "./api";


function* loginUser({ payload: { user, history } }) {
  try {

    const response = yield call(postLogin, user);
    localStorage.setItem("authUser", JSON.stringify(response.token));    
    localStorage.setItem("user", JSON.stringify(response.user));    
    yield put(loginSuccess(response));
    if(response){history.push("/dashboard")};
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("user")
        history.push("/login");
    } catch (error) {
        yield put(apiError(error.message));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

// function* authSaga() {
//   yield takeEvery(LOGIN_USER, loginUser);
//   yield takeEvery(LOGOUT_USER, logoutUser);
// }

export default authSaga;
