import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import avatar from "../../assets/images/items/avatar.png";

const ItemCard = (props) => {

    const {filteredItemList} = props;

  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              
            </div>
          </Col>
          <Col xl={9} sm={6}>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {filteredItemList.map((item, key) => (
            <Col xl={3} sm={6} key={key}>
              <Card className="shadow-none border">
                <CardBody className="p-3">
                  <div >
                    <div className="float-end ms-2">
                      <UncontrolledDropdown className="mb-2" direction="left">
                        <DropdownToggle
                          color="white"
                          className="btn btn-link text-muted mt-n2"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>

                        <DropdownMenu>
                          <Link className="dropdown-item" to="#">
                            Open
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Edit
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Rename
                          </Link>
                          <div className="dropdown-divider"></div>
                          <Link className="dropdown-item" to="#">
                            Remove
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className="avatar-xs me-3 mb-3">
                      <div className="avatar-title bg-transparent rounded">
                      <img src={item.image?item.image:avatar} width="50"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="overflow-hidden me-auto">
                        <h5 className="font-size-14 text-truncate mb-1">
                          <Link to="#" className="text-body">
                            {item.title}
                          </Link>
                        </h5>
                        <p className="text-muted text-truncate mb-0">
                          {item.desc} 
                        </p>
                      </div>
                      <div className="align-self-end ms-2">
                        <p className="text-muted mb-0"></p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ItemCard
