import React from "react"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import avatar from "../../assets/images/items/avatar.png";

const MenuList = props => {
const {filteredItemList} = props;

  return (
    <React.Fragment>
      <div className="mt-4">
        <div className="d-flex flex-wrap">
          <h5 className="font-size-16 me-3">Menu List</h5>
        </div>
        <hr className="mt-2" />

        <div className="table-responsive file-manager">
          <Table className="table align-middle table-nowrap table-hover mb-0">
            <thead>
              <tr>
                <th scope="col" style={{width:"50px"}}>#</th>
                {/* <th scope="col" style={{width:"150px"}}>Image</th> */}
                <th scope="col">Name</th>
                <th scope="col">Description</th>

              </tr>
            </thead>
            <tbody>
              {filteredItemList.map((item, key) => (
                <tr key={key}>
                  <td>{key+1}</td>
                  {/* <td><img src={item.image?item.image:avatar} width="50"/></td> */}
                  <td>{item.title}</td>
                  <td>{item.desc}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MenuList;
