import React,{useState,useRef, useEffect} from "react";
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import io from "socket.io-client";
import immer from "immer";

const initialMessagesState = {
  general: [],
};
const rooms = ["general"];  

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  setHasResponse,
  hasAccess,
  // socketRef,
  // currentChat,
  // setCurrentChat,
  // connectedRooms,
  // setConnectedRooms,
  // allUsers,
  // setAllUsers,
  // messages,
  // setMessages,
  // message,
  // setMessage,
  // messageBox,
  // setMessageBox,
  // contacts,
  // setContacts,  
  // user,
  // currentUser,
  // rooms,
  // username,
  ...rest
}) =>
{
  

 
  const user = JSON.parse(localStorage.getItem("user"));
  const socketRef = useRef();
  const [username, setUsername] = useState();
  const [currentUser, setCurrentUser] = useState();  
  const [currentChat, setCurrentChat] = useState({isChannel: true, chatName: "general", receiverId: ""});
  const [connectedRooms, setConnectedRooms] = useState(["general"]);
  const [allUsers, setAllUsers] = useState([]);
  const [messages, setMessages] = useState(initialMessagesState);
  const [message, setMessage] = useState("");
  const [messageBox, setMessageBox] = useState(null);
  const [contacts, setContacts] = useState([]);  

  const roomJoinCallback=(incomingMessages, room)=>{
    const newMessages = immer(messages, (draft) => {
      draft[room] = incomingMessages;
    });
    setMessages(newMessages);
  }
  
  // useEffect(() =>
  // {  console.log(user)
  //   if (user)
  //   {
  //     setUsername(user.username);
  //     setCurrentUser({
  //       ...user,
  //       name: user.username,
  //     });  
  //     socketRef.current = io.connect("https://api.palazzodip.com");
  // socketRef.current.emit("join server", username);
  // socketRef.current.emit("join room", "general", (messages) => roomJoinCallback(messages, "general"));
  // socketRef.current.on("new user", (allUsers) => {
  //   setAllUsers(allUsers);
  // });
  // socketRef.current.on("new message", ({content, sender, chatName, createdAt}) => {
  //   setMessages((messages) => {
  //     const newMessages = immer(messages, (draft) => {
  //       if (draft[chatName]) {
  //         draft[chatName].push({content, sender, createdAt});
  //       } else {
  //         draft[chatName] = [{content, sender, createdAt}];
  //       }
  //     });
  //     return newMessages;
  //   });
  // });    
  //   console.log("connected", socketRef.current);
  //   }
  // }, [])
    
return  (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      return (
        <Layout>
          <Component
            {...props}
            socketRef ={socketRef}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            connectedRooms={connectedRooms}
            setConnectedRooms={setConnectedRooms}
            allUsers={allUsers}
            setAllUsers={setAllUsers}
            messages={messages}
            setMessages={setMessages}
            message={message}
            setMessage={setMessage}
            messageBox={messageBox}
            setMessageBox={setMessageBox}
            contacts={contacts}
            setContacts ={setContacts}  
            user={user}
            currentUser={currentUser}
            rooms={rooms}
            username={username}
          />
        </Layout>
      )
    }}
  />
)
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  hasAccess: PropTypes.bool,
  zoo:PropTypes.any
}

export default Authmiddleware;
