import React, {useState, useEffect} from "react";
import {Row, Card, CardBody, Container, Form, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {withRouter} from "react-router-dom";
import {useListToggle, useToggle} from "../../helpers/hooks";
import DualListBox from "react-dual-listbox";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
const { v4: uuidv4 } = require("uuid");
import { useFormik } from "formik";
import * as Yup from "yup";
// import Component
import FileLeftBar from "./FileLeftBar";
import ItemCard from "./ItemCard";
import ItemList from "./ItemList";
import MenuCard from "./MenuCard";
import MenuList from "./MenuList";

import {getAxiosDefaultConfig, getId} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import {ToastContainer, toast} from "react-toastify";

const MenusLists = (props) => {
  //meta title
  document.title = "Menus | Admin Events";

  const user = JSON.parse(localStorage.getItem("user"));
  const [modal, setModal] = useState(false);
  const [hasResponse, setHasResponse] = useState();
  const [activeTab, setactiveTab] = useState("1");
  const [showList, toggleListLayout] = useListToggle();
  const [itemList, setItemList] = useState([]);
  const [filteredItemList, setFilteredItemList] = useState([]);

  const [selectedPreserve, setSelectedPreserve] = useState([]);

  const [menuList, setMenuList] = useState([]);
  const [itemsList, setItemsList] = useState([]);


  const getAllItems = (user) => {
    const url = API_URL + "/menus/items/all";
    axios
      .get(url, getAxiosDefaultConfig())
      .then((response) => {
        setItemsList(response.data.items);
        setFilteredItemList(response.data.items);
        const ty = response.data.items.map((item) => {
          return { value: item.id, label: item.title };
        });
        setItemList(ty)
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      });
  };

  const getAllMenus = (user) => {
    const url = API_URL + "/menus/all";
    axios
      .get(url, getAxiosDefaultConfig())
      .then((response) => {
        setMenuList(response.data.menus)
        setFilteredItemList(response.data.menus);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      });
  };

  useEffect(() => {
    getAllItems()
    getAllMenus()
  }, []);
  
  const filterItemList = (e) => {
    const term = e.target.value;
    if (term.length >= 2) {
      const newItemList = activeTab=== "1"?[...menuList]:[...itemsList];

      const newArray = newItemList.filter((obj) => {
        return Object.values(obj).some((value) => {
          return typeof value === "string" && value.toLowerCase().includes(term.toLowerCase());
        });
      });

      setFilteredItemList(newArray);
    } else if (term.length === 0) {
      setFilteredItemList(activeTab=== "1"? menuList : itemsList);
    }
  };

  const onPreserveChange = (selectedPreserve) => {
    setSelectedPreserve(selectedPreserve);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
    if (tab === "1")
    {
      setFilteredItemList(menuList); 
    } else
    {
      setFilteredItemList(itemsList);
    }
  };

  const submitForm = async (values) => {
    setHasResponse(null);

    const formData = new FormData();
    const data = { ...values, items: selectedPreserve.toString() };

    if (modal) {
      const url = API_URL + "/menus/" + editItem.id;
      try {
        await axios
          .put(url, formData, getAxiosDefaultConfig())
          //.then((res) => res.data)
          .then((res) => {
            setHasResponse(res.data.message);
          })
          .catch((error) => {
            console.log(error);
            setHasResponse(error.response.data.message);
          });
      } catch (error) {
        console.log(error.response.data.error);
        setHasResponse(error.response.data.message);
      }
    } else {
      const url = API_URL + "/menus";
      try {
        await axios
          .post(url, data, getAxiosDefaultConfig())
          .then((res) => {
            setHasResponse(res.data.message);
            setSelectedPreserve([])
            validation.values.name=""
            getAllMenus()
          })
          .catch((error) => {
            console.log(error);
            setHasResponse(error.response.data.message);
          });
      } catch (error) {
        console.log(error.response.data.error);
        setHasResponse(error.response.data.message);
      }
    }
  };

  useEffect(() =>
  {
    toast(hasResponse);
  }, [hasResponse]);
  
  const validation = useFormik({
    enableReinitialize: false,

    initialValues: {
      id: uuidv4(),
      name: "",
    },
    validationSchema: Yup.object({
      id: Yup.string(),
      name: Yup.string().nullable().required("Name is required").max(100, "Up to 100 characters"),
    }),
    onSubmit: (values) => {
      submitForm(values);        
    }, 
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" showList={showList} toggleListLayout={toggleListLayout} />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                {/* FileRightBar  */}
                <FileLeftBar user={user} getAllItems={getAllItems} />
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <div lg={12}>
                        <DualListBox
                          //disabled={!currentUser.canAsignMenus}
                          canFilter
                          filterCallback={(itemList, filterInput) => {
                            if (filterInput === "") {
                              return true;
                            }

                            return new RegExp(filterInput, "i").test(itemList.label);
                          }}
                          filterPlaceholder="Search..."
                          options={itemList}
                          selected={selectedPreserve}
                          onChange={onPreserveChange}
                          preserveSelectOrder
                          showOrderButtons
                        />
                      <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                          }}>     
                        <input type="hidden" name="id" id="id" value={validation.values.id} />  
                        <div className="position-relative mt-3">
                          <Button disabled={!user.canAddItems} type="submit" color="primary" className="btn mt-0 mb-2 me-2" style={{float: "right", marginLeft: "15px"}}>
                            <i className="mdi mdi-plus me-1" />
                            Create menu
                          </Button>
                          <label htmlFor="name" className="search-label" style={{width: "300px", float: "right"}}>
                              <span id="search-bar-0-label" className="sr-only">
                              {validation.errors.name ? <span style={{color: "red"}}>{validation.errors.name}</span> : "Enter menu name"}                              
                            </span>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              placeholder="Menu name"
                              value={validation.values.name}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className={validation.errors.name ? "form-control is-invalid" : "form-control"}                                
                            />
                          </label>
                        </div>
                      </Form>
                      </div>
                      <div lg={12}>
                        <Nav tabs style={{marginTop: "80px"}}>
                          <NavItem>
                            <NavLink
                              style={{cursor: "pointer"}}
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggle("1");
                              }}>
                              Menus
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{cursor: "pointer"}}
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggle("2");
                              }}>
                              Items
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="p-3 text-muted">
                          <TabPane tabId="1">
                            <Row>
                              <div style={{marginTop: "20px"}}>
                                <div className="search-box d-inline-block" style={{width: "50%"}}>
                                  <div className="position-relative">
                                    <label htmlFor="search-bar-0" className="search-label" style={{width: "100%"}}>
                                      <span id="search-bar-0-label" className="sr-only">
                                        Search
                                      </span>
                                      <input
                                        id="search-bar-0"
                                        type="text"
                                        className="form-control"
                                        name="containerSearch"
                                        // placeholder={`${filteredItemList.length} records...`}
                                        placeholder={filteredItemList.length > 1 ? filteredItemList.length + " records..." : filteredItemList.length + " record..."}
                                        onChange={(e) => {
                                          filterItemList(e);
                                        }}
                                      />
                                    </label>
                                    <i className="bx bx-search-alt search-icon"></i>
                                  </div>
                                </div>
                              </div>
                              {!showList && <MenuCard  filteredItemList={filteredItemList} />}
                              {showList && <MenuList  filteredItemList={filteredItemList} />}
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <div style={{marginTop: "20px"}}>
                                <div className="search-box d-inline-block" style={{width: "50%"}}>
                                  <div className="position-relative">
                                    <label htmlFor="search-bar-0" className="search-label" style={{width: "100%"}}>
                                      <span id="search-bar-0-label" className="sr-only">
                                        Search
                                      </span>
                                      <input
                                        id="search-bar-0"
                                        type="text"
                                        className="form-control"
                                        name="containerSearch"
                                        // placeholder={`${filteredItemList.length} records...`}
                                        placeholder={filteredItemList.length > 1 ? filteredItemList.length + " records..." : filteredItemList.length + " record..."}
                                        onChange={(e) => {
                                          filterItemList(e);
                                        }}
                                      />
                                    </label>
                                    <i className="bx bx-search-alt search-icon"></i>
                                  </div>
                                </div>
                              </div>
                              {!showList && <ItemCard  filteredItemList={filteredItemList} />}
                              {showList && <ItemList  filteredItemList={filteredItemList} />}
                            </Row>
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MenusLists);
