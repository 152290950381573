import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import {connect} from 'react-redux';
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import CarouselPage from "./CarouselPage";


// actions
import {apiError, loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";


const Login = props => {
  const [passwordShown, setPasswordShown] = useState(false);
   //meta title
  document.title="Login | Admin Events";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid Email").required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  const { error } = useSelector(state => ({
    error: `${state.Login.error}`,
  }));
  
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    // <React.Fragment>
    //   <div className="home-btn d-none d-sm-block">
    //     <Link to="/" className="text-dark">
    //       <i className="fas fa-home h2" />
    //     </Link>
    //   </div>
    //   <div className="account-pages my-5 pt-sm-5">
    //     <Container>
    //       <Row className="justify-content-center">
    //         <Col md={8} lg={6} xl={5}>
    //           <Card className="overflow-hidden">
    //             <div className="bg-primary">
    //               <Row>
    //                 <Col xs={6}>
    //                   <div className="text-dark p-4">
    //                     <h5 className="text-dark">Welcome</h5>
    //                     <p>Sign in to continue.</p>
    //                   </div>
    //                 </Col>
    //                 <Col className="col-6 align-self-end">
    //                   <img src={profile} alt="" className="img-fluid" />
    //                 </Col>
    //               </Row>
    //             </div>
    //             <CardBody className="pt-0">
    //               <div>
    //                 <Link to="/" className="auth-logo-light">
    //                   <div className="avatar-md profile-user-wid mb-4">
    //                     <span className="avatar-title rounded-circle bg-light">
    //                       <img src={logo} alt="" className="rounded-circle" height="34" />
    //                     </span>
    //                   </div>
    //                 </Link>
    //               </div>
    //               <div className="p-2">
    //                 <Form
    //                   className="form-horizontal"
    //                   onSubmit={(e) => {
    //                     e.preventDefault();
    //                     validation.handleSubmit();
    //                     return false;
    //                   }}>
    //                   {error ? <Alert color="danger">{error}</Alert> : null}
    //                   <div className="form-floating mb-1">
    //                     <input
    //                       className={validation.touched.email && validation.errors.email ? "form-control is-invalid" : "form-control"}
    //                       autoComplete="off"
    //                       name="email"
    //                       id="email"
    //                       value={validation.values.email || ""}
    //                       onChange={validation.handleChange}
    //                       onBlur={validation.handleBlur}
    //                       type="email"
    //                     />
    //                     <label htmlFor="email">{validation.touched.email && validation.errors.email ? <span style={{color: "red"}}>{validation.errors.email}</span> : "Enter your email"}</label>
    //                   </div>
    //                   <div className="form-floating mb-1 position-relative">
    //                     <input
    //                       className={validation.errors.password ? "form-control is-invalid" : "form-control"}
    //                       autoComplete="off"
    //                       name="password"
    //                       id="password"
    //                       value={validation.values.password || ""}
    //                       onChange={validation.handleChange}
    //                       type={passwordShown ? "text" : "password"}
    //                     />
    //                     {/* <span className="viewpass" style={{right: validation.errors.password ? "33px" : "11px"}} onClick={togglePassword}>
    //                       {passwordShown ? <i className="text-muted far fa-eye-slash" /> : <i className="text-muted far fa-eye" />}
    //                     </span> */}
    //                     <label htmlFor="password">{validation.touched.password && validation.errors.password ? <span style={{color: "red"}}>{validation.errors.password}</span> : "Enter your password"}</label>                       
    //                   </div>
    //                   <div className="form-check">
    //                     <input type="checkbox" className="form-check-input" id="customControlInline" />
    //                     <label className="form-check-label" htmlFor="customControlInline">
    //                       Remember me
    //                     </label>
    //                   </div>

    //                   <div className="mt-3 d-grid">
    //                     <button className="btn btn-primary btn-block" type="submit">
    //                       Log In
    //                     </button>
    //                   </div>

    //                   <div className="mt-4 text-center">
    //                     <Link to="/reset-password" className="text-muted">
    //                       <i className="mdi mdi-lock me-1" />
    //                       Forgot your password?
    //                     </Link>
    //                   </div>
    //                 </Form>
    //               </div>
    //             </CardBody>
    //           </Card>
    //           <div className="mt-5 text-center">
    //             <p>
    //               Don&#39;t have an account ?{" "}
    //               <Link to="/register" className="fw-medium text-primary">
    //                 {" "}
    //                 Signup now{" "}
    //               </Link>{" "}
    //             </p>
    //             <p>
    //               © {new Date().getFullYear()}{" "}
    //               <a href="https://www.deous.gr" target={"_blank"} rel="noreferrer">
    //                 {" "}
    //                 Deous
    //               </a>
    //             </p>
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
<React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logo}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logo}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue.
                        </p>
                      </div>

                      <div className="mt-4">
                      <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <div className="form-floating mb-1">
                        <input
                          className={validation.touched.email && validation.errors.email ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="email"
                          id="email"
                          value={validation.values.email || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          type="email"
                        />
                        <label htmlFor="email">{validation.touched.email && validation.errors.email ? <span style={{color: "red"}}>{validation.errors.email}</span> : "Enter your email"}</label>
                      </div>
                      <div className="form-floating mb-1 position-relative">
                        <input
                          className={validation.errors.password ? "form-control is-invalid" : "form-control"}
                          autoComplete="off"
                          name="password"
                          id="password"
                          value={validation.values.password || ""}
                          onChange={validation.handleChange}
                          type={passwordShown ? "text" : "password"}
                        />
                        {/* <span className="viewpass" style={{right: validation.errors.password ? "33px" : "11px"}} onClick={togglePassword}>
                          {passwordShown ? <i className="text-muted far fa-eye-slash" /> : <i className="text-muted far fa-eye" />}
                        </span> */}
                        <label htmlFor="password">{validation.touched.password && validation.errors.password ? <span style={{color: "red"}}>{validation.errors.password}</span> : "Enter your password"}</label>                       
                      </div>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customControlInline" />
                        <label className="form-check-label" htmlFor="customControlInline">
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block" type="submit">
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/reset-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>

                        <div className="mt-5 text-center">
                                     <p>
                   Don&#39;t have an account ?{" "}
                   <Link to="/register" className="fw-medium text-primary">
                     {" "}
                     Signup now{" "}
                   </Link>{" "}
                 </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                                <p>
                 © {new Date().getFullYear()}{" "}
                  <a href="https://www.deous.gr" target={"_blank"} rel="noreferrer">
                     {" "}
                     Deous
                   </a>
                </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>    
  );
};

const mapStatetoProps = state => {
    const { error , loading} = state.Login;
    return { error, loading};
}

// export default withRouter(Login);
export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(Login));

Login.propTypes = {
  history: PropTypes.object,
};
