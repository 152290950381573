import React, { useEffect, useState, useMemo } from "react";
import {useListToggle, useToggle} from "../../helpers/hooks";
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { API_URL} from '../../config';
import axios from "axios";
import { getAxiosDefaultConfig } from '../../utils';
import logo from "../../assets/images/users/home.png";

import DeleteModal from "../../components/Common/DeleteModal";

import CardContainer from "../../components/Common/CardContainer";

import TableContainer from '../../components/Common/TableContainer';

import AddAccommodationForm from '../../components/Common/AccommodationNewForm'

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import moment from "moment";
import {locale} from "moment";
moment.locale('el')

const Users = props => {
  
  //meta title
  document.title="Accommodations | Admin Events";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showList, toggleListLayout] = useListToggle();
    const [user, setUser] = useState({})
    const [accommodationList, setAccommodationList] = useState([]);
    const [filteredPropertyList, setFilteredPropertyList] = useState([]);
    const [deleteAccommodation, setDeleteAccommodation] = useState({})
    const [hasResponse, setHasResponse] = useState();
    const [accommodationActionBtn, setAccommodationActionBtn] = useState({})
    const [active, setActive] = useState([])
    const [userActionBtn, setUserActionBtn] = useState({});

    useEffect(() =>
    {     
        const user = JSON.parse(localStorage.getItem("user"));
        setUser(user)       
      getAccommodationList(user)
    }, []);


    useEffect(() => {
      toast(hasResponse);
    }, [hasResponse])
  useEffect(() => {
    toast(userActionBtn);
  }, [userActionBtn]);

  useEffect(() => {
    setFilteredPropertyList(accommodationList);
  },[showList])  

  const getAccommodationList = (user) =>
  {
     const url = API_URL + "/accommodations/all";
      axios
          .get(url,getAxiosDefaultConfig())
          .then((response) =>
          {
            setAccommodationList(response.data.accommodations)
            setFilteredPropertyList(response.data.accommodations);
          })
          .catch(error =>
          {          
            console.log(error.response.data.error);
            toast.error(error.response.data.error);
          });
}
     
  const activateAccommodation = async(value,e) =>
  { 
    setHasResponse(null)      
    const data = {
      ...value,
      active: e.target.checked ? 1 : 0,
    };  
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const url = API_URL + "/accommodations/" + data.id;
    try {
      await axios
        .put(url, formData, getAxiosDefaultConfig())
        //.then((res) => res.data)
        .then((res) => {
          setHasResponse(res.data.message);
          getAccommodationList(user)
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data.message);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
    }   
  }  

  const filterPropertyList = (e) => {
    const term = e.target.value;
    if (term.length >= 2) {
      const newPropertyList = [...accommodationList];

      const newArray = newPropertyList.filter((obj) => {
        return Object.values(obj).some((value) => {
          return typeof value === "string" && value.toLowerCase().includes(term.toLowerCase());
        });
      });

      setFilteredPropertyList(newArray);
    } else if (term.length === 0) {
      setFilteredPropertyList(accommodationList);
    }
  };

  const activateProperty = async (value, e) => {
    setHasResponse(null);
    const data = {
      ...value,
      ...value.user_rights.menu_options,
      ...value.user_rights.order_options,
      ...value.user_rights.schedule_options,
      ...value.user_rights.accommodation_options,
      user_rights: undefined,
      active: e.target.checked ? 1 : 0,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    const url = API_URL + "/users/" + data.id;
    try {
      await axios
        .put(url, formData, getAxiosDefaultConfig())
        //.then((res) => res.data)
        .then((res) => {
          setHasResponse(res.data.message);
          getUserList()
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data.message);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
    }
  };  

      const columns = useMemo(
        () => [
            {
                Header: '#',
                accessor: '',
                disableFilters: true,
              Cell: (cellProps) =>
              {
                return (<div>
                   {cellProps.row.id}
                   {cellProps.row.index}
                  </div>)
                }                  
            },  
            {
                Header: 'Image',
                accessor: 'logo',
                disableFilters: true,
              Cell: (cellProps) =>
              {
                return (<div>
                   <img src={cellProps.value != null ? cellProps.value : logo} alt="avatar"  width="40" height="40" className="tableAvatarImg"/>
                  
                  </div>)
                }                   
          },          
            {
                Header: 'Name ',
                accessor: 'name',
                disableFilters: true,               
            },             

            {
                Header: 'Owner ',
                accessor: 'owner.name',
                disableFilters: true,                
            },
            {
                Header: 'Created at ',
                accessor: 'created',
              disableFilters: true,
              Cell: (cellProps) =>
              {
                return (<div>
                   {moment(cellProps.value).format('DD/MM/YYYY').toString()  }
                  
                  </div>)
                }
            },
            {
                Header: 'Active ',
                accessor: 'active',
                disableFilters: true,
              Cell: (cellProps) =>
              {
                  return (
                    <div className="form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked={cellProps.value}
                        onClick={(e)=>{activateAccommodation(cellProps.row.original,e)}}
                      />
                    </div>
                  );
                }                         
          },   
          {
            Header: 'Actions',
            accessor: 'id',
            disableFilters: true,
            Cell: (cellProps) =>
            {
              const user = JSON.parse(localStorage.getItem("user"));
              return (               
                <div className="gap-3">
                  <Link to={`/property/${cellProps.value}`}
                    className="btn btn-success"
                  >
                    <i className="mdi mdi-pencil font-size-13"></i>
                  </Link>  
                  <button
                    disabled={!user.canAddDeleteAccommodation}
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      const userData = cellProps.row.original;
                      onClickDelete(userData);
                    }}                    
                  >
                    <i className="mdi mdi-trash-can font-size-13"></i>{" "}
                  </button>                  
                </div>
              );
            }
          },            
        ],
        []
  );


  
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  
  //delete user
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (accommodation) => {
    
    setDeleteModal(true);
    setDeleteAccommodation(accommodation)
  };

  const handleDeleteAccommodation = async() => {
       
      const url = API_URL + "/accommodations/" + deleteAccommodation.id;
      try{  
        await axios
          .delete(url, getAxiosDefaultConfig())
          .then((res)=>{
            getAccommodationList(JSON.parse(localStorage.getItem("user")));
            setHasResponse(res.data.message)
            setDeleteModal(false)
            setDeleteAccommodation({})
          }
        )
          .catch(error =>
          {
            console.log(error)
            setHasResponse(error.response.data.message)
            setDeleteModal(false)
            setDeleteAccommodation({})
          }); 
          }
      catch (error) {
            console.log(error.response.data.error)
            setHasResponse(error.response.data.message)
            setDeleteModal(false)
            setDeleteAccommodation({})
          }     

  };


  const handleAddPropertyClick = () => {
    setIsEdit(false);
    toggle();
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAccommodation}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Breadcrumbs title="" showList={showList} toggleListLayout={toggleListLayout} />
        <Container fluid>
          <Row>
            <Col xs="12">
              {showList && (
                <Card>
                  <CardBody>
                    <TableContainer
                      hasAccommodationLimit={user.maxAccommodations >= accommodationList.length ? false : true}
                      canAddDeleteAccommodation={user.canAddDeleteAccommodation}
                      columns={columns}
                      data={accommodationList}
                      isGlobalFilter={true}
                      isAddAccommodationList={true}
                      handleUserClick={handleAddPropertyClick}
                      customPageSize={25}
                      customPageSizeOptions={true}
                      className="custom-header-css"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
          {!showList && (
              <>
                <Col lg={4}>
                  <div className="search-box d-inline-block" style={{width: "100%"}}>
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label" style={{width: "100%"}}>
                        <span id="search-bar-0-label" className="sr-only">
                          Search users
                        </span>
                        <input
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          name="containerSearch"
                          placeholder={`${accommodationList.length} records...`}
                          onChange={(e) => {
                            filterPropertyList(e);
                          }}
                        />
                      </label>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="text-sm-end">
                    <Button disabled={user.maxClients >= accommodationList.length ? false : true} type="button" color="primary" className="btn mb-4" onClick={handleAddPropertyClick}>
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Add new property
                    </Button>
                  </div>
                </Col>
              </>
            )}
            {!showList &&
              filteredPropertyList.map((property, index) => {
                return (
                  <CardContainer
                    key={index}
                    hasClientLimit={user.maxProperty >= accommodationList.length ? false : true}
                    property={property}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleAddPropertyClick}
                    className="custom-header-css"
                    activateProperty={activateAccommodation}
                    
                  />
                );
              })}            
          </Row>
        </Container>       
          <Modal isOpen={modal} toggle={toggle} size={"lg"}>
            <ModalHeader toggle={toggle} tag="h4">
              Add property
            </ModalHeader>
            <ModalBody>
            <AddAccommodationForm
              toggle={toggle}
              currentUser={user}
              getAccommodationList={getAccommodationList}
              setHasResponse={setHasResponse}
            />
            </ModalBody>
          </Modal>               
      </div>
      <ToastContainer
        toastStyle={{ backgroundColor: "#f46a6a", color: "white",textAlign:"center"}} 
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        limit={3}
      />
    </React.Fragment>
  );
};

export default Users;
