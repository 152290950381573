import React, {useEffect, useState, useRef} from "react";
import PropTypes, {number} from "prop-types";
import {Link} from "react-router-dom";
import {isEmpty, map} from "lodash";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import {API_URL} from "../../config";
import axios from "axios";
import {getAxiosDefaultConfig} from "../../utils";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import avatar from "../../assets/images/users/avatar.png";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import images from "assets/images";
import io from "socket.io-client";
import immer from "immer";
import {locale} from "moment";
moment.locale("el");


// const socket = io.connect("https://api.palazzodip.com", {autoConnect: false});

const Chat = ({
    socketRef,
    currentChat,
    setCurrentChat,
    connectedRooms,
    setConnectedRooms,
    allUsers,
    setAllUsers,
    messages,
    setMessages,
    message,
    setMessage,
    messageBox,
    setMessageBox,
    contacts,
    setContacts,
    user,
    currentUser,
    rooms,
    username,
    ...props
}) =>
{
      
  //meta title
  document.title = "Chat | Admin Events";

  const [search_Menu, setsearch_Menu] = useState(false);
  const [settings_Menu, setsettings_Menu] = useState(false);
  const [other_Menu, setother_Menu] = useState(false);
  const [activeTab, setactiveTab] = useState("1");

   
  const getUserList = async (user) => {
    const url = API_URL + "/users/all";
    await axios
      .get(url, getAxiosDefaultConfig())
      .then((response) => {
        setContacts(response.data.users);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };


  useEffect(() => {
      getUserList();
  }, []);
       

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  }

  const createMessage = async(message) =>  {

    const url = API_URL + "/messages";
    await axios
        .post(url,message,getAxiosDefaultConfig())
        .then((response) =>
        {         
         console.log(response)
        })
        .catch(error =>
        {          
          console.log(error.response);
        }); 

  }

  const sendMessage = () => {
    const payload = {
      content: message,
      to: currentChat.isChannel ? currentChat.chatName : currentChat.receiverId,
      from: currentChat.isChannel ? currentChat.chatName : socketRef.current.id,
      sender: username,
      chatName: currentChat.chatName,
      isChannel: currentChat.isChannel,
      createdAt: new Date(),
      };
    //  console.log(payload)
    socketRef.current.emit("send message", payload);
    const newMessages = immer(messages, (draft) => {
      draft[currentChat.chatName].push({
        sender: username,
        content: message,
        createdAt: new Date(),
      });
    });
    setMessages(newMessages);
    createMessage(payload)
  }

  const roomJoinCallback=(incomingMessages, room)=>{
    const newMessages = immer(messages, (draft) => {
      draft[room] = incomingMessages;
    });
    setMessages(newMessages);
  }

  const joinRoom=(room)=> {
    const newConnectedRooms = immer(connectedRooms, (draft) => {
      draft.push(room);
    });

    socketRef.current.emit("join room", room, (messages) => roomJoinCallback(messages, room));
    setConnectedRooms(newConnectedRooms);
  }

  const toggleChat = (currentChat)=> {
    if (!messages[currentChat.chatName]) {
      const newMessages = immer(messages, (draft) => {
        draft[currentChat.chatName] = [];
      });
      setMessages(newMessages);
    }
    setCurrentChat(currentChat);
  }

  useEffect(() => {
    setMessage("");
  }, [messages]);


  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom();
  }, [messages]);

  const toggleNotification = () => {
    setnotification_Menu(!notification_Menu);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };


  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 2000;
    }
  };

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  //serach recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();

    ul = document.getElementById(activeTab === 1 ? "recent-list" : "contact-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const renderUser = (user) => {
    const contactUser = contacts ? contacts.find((contact) => contact.username === user.username) : currentUser;
    if (user.id !== (socketRef.current ? socketRef.current.id : "")) {
      const contactUser = contacts ? contacts.find((contact) => contact.username === user.username) : currentUser;
      const currentChat = {
        chatName: user.username,
        isChannel: false,
        receiverId: user.id,
      };
      return (
        <li key={user.id} className={user.id === (socketRef.current ? socketRef.current.id : "") ? "active" : ""}>
          <Link to="#" onClick={() => toggleChat(currentChat)}>
            <div className="d-flex">
              <div className="align-self-center me-3">
                <i className="online" />
              </div>
              <div className="align-self-center me-3">
                <img src={contactUser != null ? contactUser.avatar : avatar} className="rounded-circle avatar-xs" alt="" />
              </div>

              <div className="flex-grow-1 overflow-hidden">
                <h5 className="text-truncate font-size-14 m-1"> {user.username}</h5>
                {/* <p className="text-truncate mb-0">12</p> */}
              </div>
              <div className="font-size-11">
                <i className="mdi mdi-circle text-success font-size-12"/>
              </div>
            </div>
          </Link>
        </li>
      );
    }
  }

  const renderRooms = (room) => {
    const currentChat = {
      chatName: room,
      isChannel: true,
      receiverId: "",
    };
    return (
      <li key={room}>
        <Link to="#" onClick={() => toggleChat(currentChat)}>
          <div className="d-flex">
            <div className="align-self-center me-3">
              <i className="mdi mdi-circle text-success font-size-12" />
            </div>
            <div className="align-self-center me-3">{/* <img src={contact.image} className="rounded-circle avatar-xs" alt="" /> */}</div>

            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-14 m-1"> {room}</h5>
            </div>
          </div>
        </Link>
      </li>
    );
  }

  const copyMessage = (message,index) =>
  {
    const thisMessage = message.content;
    navigator.clipboard.writeText(thisMessage)
    console.log(thisMessage)
  }
  
  const replyMessage = (message,index) =>
  {
    const thisMessage = message.sender+" wrote: <br/><hr/><br/> "+message.content;
    setMessage(thisMessage)
  }
  
  const deleteMessage = (message,index) =>
  {
    const payload = {
      index,
      chatName: currentChat.chatName
    };
    socketRef.current.emit("delete message", payload);
    const thisMessage = message.sender + "" + " wrote: <br/><hr/><br/> " + message.content;
    const newMessage = [...messages[currentChat.chatName]]
    console.log(newMessage[index])
    newMessage.splice(index, 1)
    console.log(newMessage[index])
    console.log(newMessage)
    setMessages({general: newMessage}) 
    console.log(messages)
    // console.log(index,thisMessage)
  }
  

  const renderMessages = (message, index) =>
  {
    return (
      <li key={index} className={message.sender === currentUser.name ? "right" : "left"}>
        <div className="conversation-list">
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="btn nav-btn p-0" tag="i">
              <i className="bx bx-dots-vertical-rounded" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="#" onClick={() => copyMessage(message, index)}> Copy </DropdownItem>
              <DropdownItem href="#" onClick={()=> replyMessage(message,index)}> Reply </DropdownItem>
              <DropdownItem href="#" onClick={() => deleteMessage(message,index)}> Delete </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <p className={message.sender === currentUser.name ? "chat-time mb-0 pt-0 pb-0 p-2 text-right" : "chat-time mb-0 pt-0 pb-0 p-2 text-left"}>{moment(message.createdAt).format("DD-MM-yyyy")}</p>
          <div className="ctext-wrap">
            <div className="conversation-name" style={{color: "black"}}>
              {message.sender}
            </div>
            <p>{message.content}</p>
            <p className={message.sender === currentUser.name ? "chat-time mb-0 pt-0 pb-0 p-2 text-right" : "chat-time mb-0 pt-0 pb-0 p-2 text-left"}>
              <i className="bx bx-time-five align-middle me-1" />
              {moment(message.createdAt).format("hh:mm")}
            </p>
          </div>
        </div>
      </li>
    );
  }

  const handleKeypress = (e) => {
    if (e.key === "Enter" && message!=="" && message!==" ") {
      sendMessage();
    }
  }

  let messageBody;
  if (!currentChat.isChannel || connectedRooms.includes(currentChat.chatName)) {
    if (messages[currentChat.chatName]) messageBody = <span>{messages[currentChat.chatName].map(renderMessages)}</span>;
  } else {
    messageBody = (
      <Button type="button" color="primary" onClick={() => joinRoom(currentChat.chatName)} className="btn btn-primary btn-rounded chat-send w-md ">
        <span className="d-none d-sm-inline-block me-2">Join </span> <i className="mdi mdi-send" />
      </Button>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Chat" />
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <img src={user.image ? user.image : images.avatar1} className="avatar-xs rounded-circle" alt="avatar" />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">{currentUser.name}</h5>
                          <div className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success font-size-10"></i>
                            Active
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input onKeyUp={searchUsers} id="search-user" type="text" className="form-control" placeholder="Search..." />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}>
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Online</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2");
                            }}>
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Channels</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <PerfectScrollbar style={{height: "410px"}}>
                              <ul className="list-unstyled chat-list" id="recent-list">
                                {allUsers.map(renderUser)}
                              </ul>
                            </PerfectScrollbar>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <PerfectScrollbar style={{height: "410px"}}>
                              <ul className="list-unstyled chat-list" id="contact-list">
                                {rooms.map(renderRooms)}
                              </ul>
                            </PerfectScrollbar>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <h5 className="font-size-15 mb-1" style={{textTransform: "capitalize"}}>
                            {currentChat.chatName}
                          </h5>

                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-1"/>
                            Active
                          </p>
                        </Col>
                        <Col md="8" xs="3">
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown isOpen={search_Menu} toggle={toggleSearch}>
                                <DropdownToggle className="btn nav-btn" tag="i">
                                  <i className="bx bx-search-alt-2" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-md">
                                  <Form className="p-3">
                                    <FormGroup className="m-0">
                                      <InputGroup>
                                        <Input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        {/* <InputGroupAddon addonType="append"> */}
                                        <Button color="primary" type="submit">
                                          <i className="mdi mdi-magnify" />
                                        </Button>
                                        {/* </InputGroupAddon> */}
                                      </InputGroup>
                                    </FormGroup>
                                  </Form>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item  d-none d-sm-inline-block">
                              <Dropdown isOpen={settings_Menu} toggle={toggleSettings}>
                                <DropdownToggle className="btn nav-btn" tag="i">
                                  <i className="bx bx-cog" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="#">View Profile</DropdownItem>
                                  <DropdownItem href="#">Clear chat</DropdownItem>
                                  <DropdownItem href="#">Muted</DropdownItem>
                                  <DropdownItem href="#">Delete</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item">
                              <Dropdown isOpen={other_Menu} toggle={toggleOther}>
                                <DropdownToggle className="btn nav-btn" tag="i">
                                  <i className="bx bx-dots-horizontal-rounded" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem href="#">Action</DropdownItem>
                                  <DropdownItem href="#">Another Action</DropdownItem>
                                  <DropdownItem href="#">Something else</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">
                        <ul className="list-unstyled">
                          <PerfectScrollbar style={{height: "470px", paddingRight: "1rem"}} containerRef={(ref) => setMessageBox(ref)}>
                            <li>
                              <div className="chat-day-title">
                                <span className="title">Today</span>
                              </div>
                            </li>
                            {messageBody}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input type="textarea" value={message} onChange={handleMessageChange} onKeyPress={handleKeypress} className="form-control chat-input" placeholder="Enter your Message..." />
                              {/* <input type="text" value={curMessage} onKeyPress={onKeyPress} onChange={(e) => setcurMessage(e.target.value)} className="form-control chat-input" placeholder="Enter Message..." /> */}
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i className="mdi mdi-emoticon-happy-outline" id="Emojitooltip" />
                                      <UncontrolledTooltip placement="top" target="Emojitooltip">
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i className="mdi mdi-file-image-outline" id="Imagetooltip" />
                                      <UncontrolledTooltip placement="top" target="Imagetooltip">
                                        Images
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i className="mdi mdi-file-document-outline" id="Filetooltip" />
                                      <UncontrolledTooltip placement="top" target="Filetooltip">
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button type="button" color="primary" onClick={() => sendMessage()} className="btn btn-primary btn-rounded chat-send w-md ">
                              <span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Chat;
