import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Input, FormGroup, Form, Label, Button, TabContent, TabPane, CardText, Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone";
// Import Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getAxiosDefaultConfig, getId } from "../../utils";
import axios from "axios";
import { API_URL } from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/images/users/home.png";
import "react-toastify/dist/ReactToastify.css";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import moment from "moment";
import { locale } from "moment";
moment.locale("el");

const Accommodation = () =>
{
  let { id } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  //meta title
  document.title = "Edit Accommodation | Admin Events";

  const [currentAcc, setCurrentAcc] = useState({
    id: null,
    name: null,
    location: null,
    description: null,
    menu_list: null,
    active: null,
    logo: null,
    created: null,
    owner: { id: null, name: null },
  });
  const [selectedFiles, setselectedFiles] = useState([]);

  const [activeTab, setactiveTab] = useState("1");
  const [ownerList, setOwnerList] = useState([]);
  const [hasResponse, setHasResponse] = useState();
  const [selectedPreserve, setSelectedPreserve] = useState([
    { value: "phobos", label: "Phobos" },
    { value: "europa", label: "Europa" },
  ]);
  const [menuList, setMenuList] = useState([
    { value: "luna", label: "Moon" },
    { value: "phobos", label: "Phobos" },
    { value: "deimos", label: "Deimos" },
    { value: "io", label: "Io" },
    { value: "europa", label: "Europa" },
    { value: "ganymede", label: "Ganymede" },
    { value: "callisto", label: "Callisto" },
  ]);

  const getAccInfo = async () =>
  {
    const url = API_URL + "/accommodations/" + id;
    await axios
      .get(url, getAxiosDefaultConfig())
      .then((response) =>
      {
        setCurrentAcc(response.data);
        getOwnerList();
      })
      .catch((error) =>
      {
        console.log(error.response);
      });
  };

  const getOwnerList = () =>
  {
    const url = API_URL + "/users/all";
    axios
      .get(url, getAxiosDefaultConfig())
      .then((response) =>
      {
        const users = response.data.users.map((item) => ({
          value: item.id,
          label: item.lastname + " " + item.firstname,
        }));
        setOwnerList(users);
      })
      .catch((error) =>
      {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      });
  };

  useEffect(() =>
  {
    getAccInfo();
  }, []);

  const toggle = (tab) =>
  {
    if (activeTab !== tab)
    {
      setactiveTab(tab);
    }
  };

  useEffect(() =>
  {
    toast(hasResponse);
  }, [hasResponse]);

  function handleAcceptedFiles(files)
  {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFiles(files);
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2)
  {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submitForm = async (values) =>
  {
    setHasResponse(null);
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    const data = { ...values, ...selectedFiles[0] };
    formData.append("file", selectedFiles[0]);

    formData.append("data", JSON.stringify(data));

    const url = API_URL + "/accommodations/" + currentAcc.id;
    try
    {
      await axios
        .put(url, formData, getAxiosDefaultConfig())
        //.then((res) => res.data)
        .then((res) =>
        {
          setHasResponse(res.data.message);
          getAccInfo();
        })
        .catch((error) =>
        {
          console.log(error);
          setHasResponse(error.response.data.message);
        });
    } catch (error)
    {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: currentAcc.id,
      name: currentAcc.name,
      description: currentAcc.description,
      location: currentAcc.location,
      owner: { id: currentAcc.owner.id, name: currentAcc.owner.name },
      active: parseInt(currentAcc.active),
    },
    validationSchema: Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required("Please Enter Property Name"),
      description: Yup.string().nullable(),
      location: Yup.string().nullable(),
      active: Yup.number(),
    }),
    onSubmit: (values) =>
    {
      submitForm(values);
    },
  });

  const changeOwner = (e) =>
  {
    const newOwner = { ...currentAcc, owner: { id: e.target.value } };
    console.log(newOwner);
    setCurrentAcc(newOwner);
  };
  const activeList = [
    { label: "Active", value: 1 },
    { label: "Disabled", value: 0 },
  ];
  const changeActive = (e) =>
  {
    const newActive = { ...currentAcc, active: parseInt(e.target.value) };
    console.log(newActive);
    setCurrentAcc(newActive);
  };

  useEffect(() =>
  {
    if (selectedFiles[0]?.name != null)
    {
      let rt = selectedFiles[0]?.name;
      let ed = rt.replace(/ /g, "");
    }
  }, [selectedFiles]);

  const onPreserveChange = (selectedPreserve) =>
  {
    setSelectedPreserve(selectedPreserve);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Properties" breadcrumbItem={`${ currentAcc.name }`} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() =>
                        {
                          toggle("1");
                        }}>
                        Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() =>
                        {
                          toggle("2");
                        }}>
                        Menu settings
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() =>
                        {
                          toggle("3");
                        }}>
                        Calendar settings
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() =>
                        {
                          toggle("4");
                        }}>
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Form
                    onSubmit={(e) =>
                    {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <TabContent activeTab={activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col lg="9" sm="12">

                            <FormGroup>
                              <input type="hidden" name="id" value={validation.values.id} />
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="name" className="col-md-2 col-form-label">
                                Property Name
                              </Label>
                              <Col lg="10">
                                <div className="form-floating mb-1">
                                  <input
                                    className="form-control"
                                    autoComplete="name"
                                    name="name"
                                    id="name"
                                    placeholder="Enter name..."
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}                              
                                  />
                                  <label htmlFor="name">Enter name</label>
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="owner" className="col-md-2 col-form-label">
                                Owner Name
                              </Label>
                              <Col lg="10">
                                <div className="form-floating mb-1">
                                  <select
                                    className="form-select"
                                    styles={{ zIndex: "2" }}
                                    name="owner"
                                    id="owner"
                                    defaultValue={{ value: currentAcc.owner.id, label: currentAcc.owner.name }}
                                    onChange={(e) =>
                                    {
                                      changeOwner(e);
                                    }}
                                    onBlur={validation.handleBlur}>
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    {ownerList.map((owner) =>
                                    {
                                      return (
                                        <option key={owner.value} value={owner.value} selected={owner.value === currentAcc.owner.id ? true : false}>
                                          {owner.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label htmlFor="owner">Select property owner</label>
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="owner" className="col-md-2 col-form-label">
                                Property Status
                              </Label>
                              <Col lg="10">
                                <div className="form-floating mb-1">
                                  <select
                                    className="form-select"
                                    styles={{ zIndex: "2" }}
                                    name="active  "
                                    id="active"
                                    defaultValue={currentAcc.active}
                                    onBlur={validation.handleBlur}>
                                    <option value="" disabled>
                                      Select...
                                    </option>
                                    <option value="1">
                                      Active
                                    </option>
                                    <option value="0">
                                      Disabled
                                    </option>
                                  </select>
                                  <label htmlFor="name">Select property status</label>
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label className="col-form-label col-lg-2">Property Description</Label>
                              <Col lg="10">
                                <CKEditor
                                  editor={ClassicEditor}
                                  className="edtclass"
                                  data={validation.values.description || ""}
                                  //onBlur={validation.handleBlur}
                                  // onReady={(editor) => {
                                  // }}
                                  onChange={(event, editor) =>
                                  {
                                    const data = editor.getData();
                                    const newOwner = { ...currentAcc, description: data };
                                    setCurrentAcc(newOwner);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="name" className="col-md-2 col-form-label">
                                Property Location
                              </Label>
                              <Col lg="10">
                                <div className="form-floating mb-1">
                                  <input
                                    className="form-control"
                                    autoComplete="name"
                                    id="location"
                                    name="location"
                                    placeholder="Enter Property Location..."
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.location || ""}
                                  />
                                  <label htmlFor="name">Enter location name</label>
                                </div>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="name" className="col-md-2 col-form-label">
                                Property Image
                              </Label>
                              <Col lg="3" sm="12" className="mb-4">
                                <div style={{ backgroundImage: `url(${ currentAcc.logo ? currentAcc.logo : logo })`, backgroundSize: currentAcc.logo ? "cover" : "auto" }} className="accImg">
                                  <i
                                    className="mdi mdi-trash-can font-size-20"
                                    onClick={() =>
                                    {
                                      const newLogo = { ...currentAcc, logo: " " };
                                      setCurrentAcc(newLogo);
                                    }}></i>
                                </div>
                                <input type="hidden" name="logo" value={validation.values.logo} />
                              </Col>
                              <Col lg="7">
                                <div className="form-group">
                                  <Dropzone
                                    maxSize={2000000}
                                    onDrop={(acceptedFiles) =>
                                    {
                                      handleAcceptedFiles(acceptedFiles);
                                    }}>
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone" style={{ minHeight: "120px" }}>
                                        <div className="dz-message needsclick mt-1" {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <div className="mb-1">
                                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                                          </div>
                                          <h5>Drop files here or click to upload.</h5>
                                          <h6>Max file size 2MB.</h6>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div className="dropzone-previews mt-3" id="file-previews">
                                    {selectedFiles.map((f, i) =>
                                    {
                                      return (
                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                                              </Col>
                                              <Col className="col-auto">
                                                <Link to="#" className="text-muted font-weight-bold">
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>{f.formattedSize}</strong>
                                                </p>
                                              </Col>
                                              <Col className="text-end my-2">
                                                <button
                                                  type="button"
                                                  className="btn btn-danger w-xs"
                                                  onClick={() =>
                                                  {
                                                    setselectedFiles([]);
                                                  }}>
                                                  <i className="mdi mdi-trash-can font-size-15"></i>{" "}
                                                </button>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col lg="9" sm="12">
                            <FormGroup>
                              <input type="hidden" name="id" value={validation.values.id} />
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label htmlFor="name" className="col-md-2 col-form-label">
                                Menu List(s)
                              </Label>
                              <Col lg="10">
                                <DualListBox
                                  disabled={!currentUser.canAsignMenus}
                                  canFilter
                                  filterCallback={(menuList, filterInput) =>
                                  {
                                    if (filterInput === "")
                                    {
                                      return true;
                                    }

                                    return new RegExp(filterInput, "i").test(menuList.label);
                                  }}
                                  filterPlaceholder="Search..."
                                  options={menuList}
                                  selected={selectedPreserve}
                                  onChange={() =>
                                  {
                                    onPreserveChange();
                                  }}
                                  preserveSelectOrder
                                  showOrderButtons
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney&apos;s organic lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed
                              craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever
                              gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk vice blog. Scenester cred you probably haven&apos;t heard of them, vinyl craft beer blog stumptown. Pitchfork sustainable tofu
                              synth chambray yr.
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY,
                              art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater. Lomo wolf viral,
                              mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf salvia freegan, sartorial keffiyeh echo park vegan.
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    <div className="text-center mt-4">
                        <Button type="submit" color="primary">
                          Save changes
                        </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Accommodation;
