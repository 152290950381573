// Login Method
import axios from "axios";
import {extractUrlData, getAxiosDefaultConfig} from '../../utils';

const fetchAll = async (url) => {
  const config = getAxiosDefaultConfig();
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

const fetchEntity = async (url) => {
  const config = getAxiosDefaultConfig();
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

const postEntity = async (url, data) => {
  const config = getAxiosDefaultConfig();
  const result = extractUrlData(url, data)
  return axios
    .post(result.url, result.data, config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

const putEntity = async (url, data) => {
  const config = getAxiosDefaultConfig();
  let finalUrl = ''
  //EDIT: There are cases we need PUT without an id
  if (data.id)
    finalUrl = url + '/' + data.id
  else
    finalUrl= url
  const result = extractUrlData(finalUrl, data)
  return axios
    .put(result.url, result.data, config)
    .then((response) => {
      return response.data;
    })
    .catch(handleError);
};

const deleteEntity = async (url,data) => {
  let finalUrl = `${url}/${data.id}`
  const result = extractUrlData(finalUrl, data)
  return axios
    .delete(result.url,getAxiosDefaultConfig())
    .catch(handleError);
};

function handleError(err) {
  if (err.response) {
    const errorMsg = err.response.data.message;
    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(`Something went wrong. Error code: ${err.request.status}.`)
  }
  throw new Error("Something went wrong.")
}

export { fetchAll, fetchEntity, postEntity, putEntity, handleError, deleteEntity };
