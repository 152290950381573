import axios from "axios";
import {handleError} from "./store/core/api";
import toastr from "toastr";
import { ApiEndpoint } from "./store/core/endpoint";

//const jwtDecode = require("jwt-decode");
import jwtDecode from 'jwt-decode'

export function getToken() {
  return getAuthInfo("jwtToken");
}

export const getAxiosDefaultConfig = (blob,cancelToken) => {
  const token = getToken()

  const authorizationHeaders = {
    "X-TenantID": getTenantId()
  }

  if (token) {
    authorizationHeaders['Authorization'] = `Bearer ${token}`
  }

  if (blob)
    return {
      headers: {
        ...authorizationHeaders,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      cancelToken: cancelToken,
      responseType: "blob",
    };
  return {
    headers: {
      ...authorizationHeaders,
      "Access-Control-Allow-Origin": "*",
    }, withCredentials: true,
  };
   
};

export function getTenantId() {
  try {
    const path = window.location.pathname;
    const parts = path.split("/");
    return parts[1];
  } catch (e) {
    return null;
  }
}


export function getUsername() {
  const token = getToken();
  const decoded = jwtDecode(token);
  return decoded.sub;
}

export function getEmail() {
  const token = getToken();
  const decoded = jwtDecode(token);
  return decoded.email;
}

export function getId() {
  const token = getToken();
  const decoded = jwtDecode(token);
  return decoded.id;
}

export function getAuthInfo(prop) {
  const credentials = localStorage.getItem("authUser");
  if (!credentials) return null;
  return prop ? JSON.parse(credentials)[prop] : JSON.parse(credentials);
}

export const createMap = (array, prop = "id") => new Map(array.map((item) => [item[prop], item]));

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function makeid(length = 10) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const fetchData = (url) =>
  axios
    .get(url, getAxiosDefaultConfig())
    .then((res) => res.data)
    .catch(handleError);

export const postData = (url, data) =>
  axios
    .post(url, data, getAxiosDefaultConfig())
    .then((res) => res.data)
    .catch(handleError);

export const deleteData = (url, data) => {
  const config = {
    ...getAxiosDefaultConfig(),
    data,
  };
  return axios.delete(url, config).catch(handleError);
};

export const extractUrlData = (url, data) => {
  const { __custom_url__ } = data;
  if (__custom_url__) {
    return {
      url: __custom_url__,
      data: data.payload,
    };
  } else {
    return { url, data };
  }
};

export const truncateString = (str, num) => {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  } else return "";
};

export const role = (value) => {
     switch (value) {
      case 1108:
        return "webmaster";
      case 2707:
        return "Chef";
        case 1980:
        return "Admin";       
      case 2908:
        return "Owner";
      case 8011:
        return "Guest";
      default:
        return "user";
    }   

  }