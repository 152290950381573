import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Collapse, DropdownMenu, DropdownToggle, UncontrolledAlert, UncontrolledDropdown, Button, Form, Row, Col, Modal, ModalHeader, ModalBody} from "reactstrap";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import {useFormik} from "formik";
import {countries} from "common/languages";
import {withRouter} from "react-router-dom";
import {getAxiosDefaultConfig, getId} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
const { v4: uuidv4 } = require("uuid");
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const FileRightBar = ({user, ...props}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const [hasResponse, setHasResponse] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [editItem, setEditItem] = useState({});

  useEffect(() => {
    const item = {
      id: uuidv4(),
      name: null,
      description: null,
      image: null,
    };

    setEditItem(item);
  }, []);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFiles(files);
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEdit(true);
    } else {
      setModal(true);
    }
  };

  const submitForm = async (values) => {
    setHasResponse(null);

    const formData = new FormData();
    const data = { ...values, ...selectedFiles[0] };
    formData.append("file", selectedFiles[0]);

    formData.append("data", JSON.stringify(data));

    if (modal) {
      const url = API_URL + "/menus/items/" + editItem.id;
      try {
        await axios
          .put(url, formData, getAxiosDefaultConfig())
          //.then((res) => res.data)
          .then((res) => {
            setHasResponse(res.data.message);
          })
          .catch((error) => {
            console.log(error);
            setHasResponse(error.response.data.message);
          });
      } catch (error) {
        console.log(error.response.data.error);
        setHasResponse(error.response.data.message);
      }
    } else {
      const url = API_URL + "/menus/items";
      try {
        await axios
          .post(url, formData, getAxiosDefaultConfig())
          .then((res) => {
            setHasResponse(res.data.message);
            props.getAllItems()
          })
          .catch((error) => {
            console.log(error);
            setHasResponse(error.response.data.message);
          });
      } catch (error) {
        console.log(error.response.data.error);
        setHasResponse(error.response.data.message);
      }
    }
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: editItem.id || "",
      name: editItem.name || "",
      description: editItem.description || "",
      // secondName: editItem.secondName || "",
      // secondDescription: editItem.secondDescription || "",
      image: editItem.image || "",
    },
    validationSchema: Yup.object({
      id: Yup.string(),
      name: Yup.string().nullable().required("Name is required").max(100, "Up to 100 characters"),
      description: Yup.string().nullable().max(200, "Up to 200 characters"),
      // secondName: Yup.string().nullable().max(100, "Up to 100 characters"),
      // secondDescription: Yup.string().nullable().max(200, "Up to 200 characters"),
    }),
    onSubmit: (values) => {
      submitForm(values);     
    },
  });

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
              <div className="mb-3">
                <h5>ADD NEW PLATE</h5>
                <hr />
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                  <Row>
                    <Col md="12">
                      <input type="hidden" name="id" id="id" value={validation.values.id} />
                      <div className="form-floating mb-1">
                        <input
                          disabled={!user.canAddItems}
                          name="name"
                          id="name"
                          value={validation.values.name}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={validation.errors.name ? "form-control is-invalid" : "form-control"}
                          type="text"
                        />
                        <label htmlFor="name">{validation.errors.name ? <span style={{color: "red"}}>{validation.errors.name}</span> : "Enter name"}</label>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-floating mb-1">
                        <input
                          disabled={!user.canAddItems}
                          name="description"
                          id="description"
                          value={validation.values.description}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          className={validation.errors.description ? "form-control is-invalid" : "form-control"}
                          type="text"
                        />
                        <label htmlFor="description">{validation.errors.description ? <span style={{color: "red"}}>{validation.errors.description}</span> : "Enter description"}</label>
                      </div>
                    </Col>
                  </Row>

                  <div className="form-floating mb-1">
                    <Dropzone
                      maxSize={2000000}
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}>
                      {({getRootProps, getInputProps}) => (
                        <div className="dropzone" style={{minHeight: "160px"}}>
                          <div className="dz-message needsclick mt-1" {...getRootProps()} style={{padding: "30px 10px"}}>
                            <input {...getInputProps()} name="image" id="image" disabled={!user.canAddItems} />
                            <div className="mb-1">
                              <i className="text-muted bx bxs-cloud-upload" />
                            </div>
                            <h6>Drop files here or click to upload.</h6>
                            <h6>Max file size 2MB.</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                                </Col>
                                <Col className="col-auto">
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col className="text-end my-2">
                                  <button
                                    type="button"
                                    className="btn btn-danger w-xs"
                                    onClick={() => {
                                      setselectedFiles([]);
                                    }}>
                                    <i className="mdi mdi-trash-can font-size-15"></i>{" "}
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    <label htmlFor="image">Upload image</label>
                  </div>

                  <Button disabled={!user.canAddItems} type="submit" color="primary" className="btn mb-2 me-2">
                    <i className="mdi mdi-plus me-1" />
                    Add
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <ToastContainer
        toastStyle={{ backgroundColor: "#f46a6a", color: "white",textAlign:"center"}} 
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        limit={3}
      />
      <Modal isOpen={modal} toggle={toggle} size={"lg"} className="modal-dialog">
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "EDIT PLATE" : "ADD NEW PLATE"}
          </ModalHeader>
          <ModalBody>

          </ModalBody>
        </Modal>      
    </React.Fragment>
  );
};

export default FileRightBar;
