import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import grFlag from "../assets/images/flags/greece.jpg"


const languages = {
  sp: {
    label: "Spanish",
    flag: spain,
    id:5
  },
  gr: {
    label: "German",
    flag: germany,
    id:4
  },
  it: {
    label: "Italian",
    flag: italy,
    id:3
  },
  el: {
    label: "Greek",
    flag: grFlag,
    id:1
  },    
  en: {
    label: "English",
    flag: usFlag,
    id:2
  },
}

export const countries = [
  {
    label: "Greece",
    flag: grFlag,
    id: 1,
    phone: "+30",
  },
  {
    label: "United Kingdom",
    flag: usFlag,
    id: 2,
    phone: "+44",
  },
  {
    label: "Germany",
    flag: germany,
    id: 4,
    phone: "+49",
  },
  {
    label: "Italy",
    flag: italy,
    id: 3,
    phone: "+39",
  },
  {
    label: "Spain",
    flag: spain,
    id: 5,
    phone: "+34",
  },
];


export default languages
