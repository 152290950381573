import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, BreadcrumbItem} from "reactstrap";

const Breadcrumb = (props) => {
  return (
    <Row>
      <Col xs="12" className="m-1">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          {/* {<div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/properties">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>} */}
          {props.toggleListLayout && (
            <div className="page-title-right m-0">
              <button type="button" onClick={props.toggleListLayout} className="btn font-size-18" data-toggle="fullscreen">
                {!props.showList && <i className="bx bx-list-ul" />}
                {props.showList && <i className="bx bx-grid" />}
              </button>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
