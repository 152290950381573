import * as ActionTypes from "./actionTypes";
import {
  CUSTOM,
  DELETE,
  DELETE_FAILURE,
  DELETE_SUCCESS,
  END_POLL,
  GET,
  GET_ALL,
  GET_ALL_FAILURE,
  GET_ALL_SUCCESS,
  GET_FAILURE,
  GET_SUCCESS,
  POST,
  POST_FAILURE,
  POST_SUCCESS,
  START_POLL,
  UPDATE,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
} from "./actionTypes";
import { call, put } from "redux-saga/effects";
import {
  deleteEntity,
  fetchAll,
  fetchEntity,
  postEntity,
  putEntity,
} from "./api";
import { EntityType } from "./entityType";

export const createOperationState = (
  result = null,
  error = null,
  isLoading = false
) => ({ isLoading, error, result });

export const getCommonState = () => ({
  get: createOperationState({}),
  getAll: createOperationState([]),
  getAllPaging: createOperationState(),
  update: createOperationState(),
  create: createOperationState(),
  delete: createOperationState(),
});

export const getEntityIdentifier = (entityType) => {
  switch (entityType) {
    case EntityType.Property:
      return "propertyKey";
    case EntityType.Qualifier:
      return "qualifierKey";
    case EntityType.ContainerType:
    case EntityType.DataStreamType:
    case EntityType.Namespace:
      return "code";
    case EntityType.Container:
    case EntityType.DataStream:
    case EntityType.User:
      return "uuid";
    case EntityType.SystemSetting:
      return "settingKey";
    default:
      return "id";
  }
};

export const buildActionType = (entity, type) => entity + "." + type;

export const createPollMethod = (entityType, params, start) => {
  return {
    type: buildActionType(entityType, start ? START_POLL : END_POLL),
    params: params,
  };
};

export const createGetMethod = (entityType, params) => {
  return {
    type: buildActionType(entityType, GET),
    params: params,
  };
};

export const createGetSuccessMethod = (entityType, result) => {
  return {
    type: buildActionType(entityType, GET_SUCCESS),
    result: result,
  };
};

export const createGetFailureMethod = (entityType, error) => {
  return {
    type: buildActionType(entityType, GET_FAILURE),
    result: error,
  };
};

export const createPostMethod = (entityType, payload) => {
  return {
    type: buildActionType(entityType, POST),
    payload: payload,
  };
};

export const createPostSuccessMethod = (entityType, result) => {
  return {
    type: buildActionType(entityType, POST_SUCCESS),
    result: result,
  };
};

export const createPostFailureMethod = (entityType, error) => {
  return {
    type: buildActionType(entityType, POST_FAILURE),
    result: error,
  };
};
export const createPutMethod = (entityType, payload) => {
  return {
    type: buildActionType(entityType, UPDATE),
    payload: payload,
  };
};

export const createPutSuccessMethod = (entityType, result) => {
  return {
    type: buildActionType(entityType, UPDATE_SUCCESS),
    result: result,
  };
};

export const createPutFailureMethod = (entityType, error) => {
  return {
    type: buildActionType(entityType, UPDATE_FAILURE),
    result: error,
  };
};
export const createDeleteMethod = (entityType, payload) => {
  return {
    type: buildActionType(entityType, DELETE),
    payload: payload,
  };
};

export const createDeleteSuccessMethod = (entityType, result) => {
  return {
    type: buildActionType(entityType, DELETE_SUCCESS),
    result: result,
  };
};

export const createDeleteFailureMethod = (entityType, error) => {
  return {
    type: buildActionType(entityType, DELETE_FAILURE),
    result: error,
  };
};

export const createGetAllMethod = (entityType) => {
  return {
    type: buildActionType(entityType, GET_ALL),
    result: {},
  };
};

export const createGetAllSuccessMethod = (entityType, result) => {
  return {
    type: buildActionType(entityType, GET_ALL_SUCCESS),
    result: result,
  };
};

export const createGetAllFailureMethod = (entityType, error) => {
  return {
    type: buildActionType(entityType, GET_ALL_FAILURE),
    result: error,
  };
};

export const createCustomAction = (entityType, payload) => {
  return {
    type: buildActionType(entityType, CUSTOM),
    customType: payload.actionType,
    payload: payload,
  };
};

export const handleCommonState = (state, actionType, action, entityType) => {
  const identifierProp = getEntityIdentifier(entityType);
  switch (actionType) {
    case ActionTypes.GET_ALL:
      return {
        ...state,
        getAll: {
          ...state.getAll,
          isLoading: true,
        },
      };
    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        getAll: {
          ...state.getAll,
          error: null,
          result: Array.isArray(action.result) ?
            action.result.map((el) => ({
              ...el,
              id: el[identifierProp],
            }))
          : action.result
          ,
          isLoading: false,
        },
      };
    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        getAll: {
          ...state.getAll,
          error: action.result,
          result: [],
          isLoading: false,
        },
      };
    case ActionTypes.GET:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
        },
      };
    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          result: {
            ...action.result,
            id: action.result[identifierProp],
          },
          error: null,
          isLoading: false,
        },
        delete: {
          ...state.delete,
          error: null
        },
        update: {
          ...state.delete,
          error: null
        }
      };
    case ActionTypes.GET_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          result: {},
          error: action.result,
          isLoading: false,
        },
      };
    case ActionTypes.UPDATE:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          isLoading: true,
        },
      };
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          result: { ...action.result, id: action.result[identifierProp] },
        },
        update: {
          ...state.update,
          error: null,
          isLoading: false,
          successResult: {
            ...action.result
          },
        },
        getAll: {
          ...state.getAll,
          result: Array.isArray(state.getAll.result) ? state.getAll.result.map((item) => { if (item.id === action.result[identifierProp]) {return {...action.result, id: item.id}} return item}) : state.getAll.result
        },
      };
    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        update: {
          ...state.update,
          error: action.result,
          isLoading: false,
        },
      };
    case ActionTypes.POST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          isLoading: true,
        },
      };
    case ActionTypes.POST_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          successResult: {
            ...action.result
          },
          error: null,
          isLoading: false,
        },
        getAll: {
          ...state.getAll,
          result: [
            ...state.getAll.result,
            { ...action.result, id: action.result[identifierProp] },
          ],
        },
      };
    case ActionTypes.POST_FAILURE:
      return {
        ...state,
        create: {
          ...state.create,
          error: action.result,
          isLoading: false,
        },
      };
    case ActionTypes.DELETE:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          isLoading: true,
        },
      };
    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          isLoading: false,
        },
        getAll: {
          ...state.getAll,
          result: state.getAll.result.filter((x) => x.id !== action.result),
        },
      };
    case ActionTypes.DELETE_FAILURE:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: action.result,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

function extractUrl(url, data) {
  const {queryStringParams, __callback__, ...rest} = data
  let finalUrl = url
  let finalData = data;
  let callback = __callback__;
  if (data.queryStringParams) {
      finalUrl = url + data.queryStringParams;
      finalData = rest
  }
  if (__callback__) {
    finalData = rest
  }
  return {
    url: finalUrl, data:finalData, callback
  }
}

export function* fetchAllGen(url, successCallback, errorCallback) {
  try {
    const response = yield call(fetchAll, url);
    yield put(successCallback(response));
  } catch (error) {
    yield put(errorCallback(error.message));
  }
}

export function* fetchEntityGen(url, params, successCallback, errorCallback) {
  try {
    const finalUrl = url + "/" + params;
    const response = yield call(fetchEntity, finalUrl);
    yield put(successCallback(response));
  } catch (error) {
    yield put(errorCallback(error.message));
  }
}

export function* postEntityGen(url, data, successCallback, errorCallback) {
  try {
    const result = extractUrl(url, data)
    const response = yield call(postEntity, result.url, result.data);
    // const entityUrl = url + "/" + response.id;
    // const entity = yield call(fetchEntity, entityUrl);
    yield put(successCallback(response));
    if (result.callback) {
      yield call(result.callback, response)
    }
  } catch (error) {
    yield put(errorCallback(error.message));
  }
}

export function* putEntityGen(url, data, successCallback, errorCallback) {
  try {
    const result = extractUrl(url, data)
    const response = yield call(putEntity, result.url, result.data);
    // const entityUrl = url + "/" + data.id;
    // const entity = yield call(fetchEntity, entityUrl);
    if (result.callback) {
      yield call(result.callback, response)
    }
    yield put(successCallback(response));

  } catch (error) {
    yield put(errorCallback(error.message));
  }
}
export function* delEntityGen(url, payload, successCallback, errorCallback) {
  try {
    yield call(deleteEntity, url, payload);
    // const entityUrl = url + "/" + data.id;
    // const entity = yield call(fetchEntity, entityUrl);
    yield put(successCallback(payload.id));
  } catch (error) {
    yield put(errorCallback(error.message));
  }
}

export function* customEntityGen(payload, callback) {
  yield put(callback(payload));
}
