import React, {useState, useEffect} from "react";
import {Container, Card, CardBody, Button, Form} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

import {withRouter} from "react-router-dom";
import {getAxiosDefaultConfig} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import moment from "moment";
import {locale} from "moment";
moment.locale("el");

const AddAccommodationForm = ({setHasResponse, ...props}) => {
  const [error, setError] = useState(true);
  //meta title
  document.title = "Profile | Admin Events";

  const [addAccommodation, setAddAccommodation] = useState({});
  const [ownerList, setOwnerList] = useState([]);

  useEffect(() => {
    const url = API_URL + "/users/all";
    axios
      .get(url, getAxiosDefaultConfig())
      .then((response) => {
        setOwnerList(response.data.users);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        toast.error(error.response.data.error);
      });
  }, []);

  const submitForm = async (values) => {
    setHasResponse(null);

    const data = {...values};

    const url = API_URL + "/accommodations";
    try {
      await axios
        .post(url, data, getAxiosDefaultConfig())
        .then((res) => {
          props.toggle();
          props.getAccommodationList(JSON.parse(localStorage.getItem("user")));
          setHasResponse(res.data.message);
        })
        .catch((error) => {
          console.log(error);
          setHasResponse(error.response.data.message);
        });
    } catch (error) {
      console.log(error.response.data.error);
      setHasResponse(error.response.data.message);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      acc_user_id: "",
      user_id: props.currentUser.id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter property name"),
      acc_user_id: Yup.string().required("Please select owner"),
    }),
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const [formValidation, setValidation] = useState({
    name: null,
    acc_user_id: null,
    user_id: props.currentUser.id,
  });

  function handleSubmit(e) {
    e.preventDefault();
    const modifiedV = {...formValidation};
    var acc_user_id = document.getElementById("acc_user_id").value;
    var name = document.getElementById("name").value;

    if (acc_user_id === "") {
      modifiedV["acc_user_id"] = false;
    } else {
      modifiedV["acc_user_id"] = true;
    }
    if (name === "") {
      modifiedV["name"] = false;
    } else {
      modifiedV["name"] = true;
    }
    setValidation(modifiedV);
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                <input type="hidden" name="user_id" value={props.currentUser.id} />
                <div className="form-floating mb-1">
                  <input
                    autoComplete="off"
                    name="name"
                    id="name"
                    placeholder="Enter property name"
                    className={validation.errors.username ? "form-control is-invalid" : "form-control"}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    // invalid={validation.touched.username && validation.errors.username ? true : false}
                  />
                  <label htmlFor="name">{validation.errors.name ? <span style={{color: "red"}}>{validation.errors.name}</span> : "Enter name "}</label>
                </div>
                <div className="form-floating mb-1">
                  <select autoComplete="off" name="acc_user_id" id="acc_user_id" className="form-select" styles={{zIndex: "2"}} onChange={validation.handleChange} onBlur={validation.handleBlur}>
                    <option value="" disabled selected>
                      Select...
                    </option>
                    {ownerList.map((owner) => {
                      return (
                        <option key={owner.id} value={owner.id}>
                          {owner.lastname} {owner.firstname}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="acc_user_id">{validation.errors.acc_user_id ? <span style={{color: "red"}}>{validation.errors.acc_user_id}</span> : "Select owner"}</label>
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Save
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddAccommodationForm);
